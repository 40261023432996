import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from "./login.service";
import { NotificationService } from "../../../messages/notification.service";

@Injectable()
export class LoginInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private router: Router, private activatedRoute: ActivatedRoute, private notificationService: NotificationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const loginService = this.injector.get(LoginService);
    const newRequest = this.injectHeaders(request, loginService);
    const currentUser = JSON.parse(localStorage.getItem("backofficeUser"));

    return next.handle(newRequest).pipe(
      catchError((error) => {
        if (error.status === 401) {
          localStorage.removeItem("backofficeUser");
          loginService.handleLogin(this.router.url);
          this.notificationService.notify("Autenticação expirada, faça o login novamente.", "danger");
        } else if (error.status === 403 && !currentUser.password_expired) {
          this.notificationService.notify("Você não tem permissão para acessar essa página.", "danger");
          this.router.navigate([`/backoffice/dashboard`]);
        } else if (error.status === 500) {
          this.notificationService.notify("Ocorreu um erro inesperado.", "danger");
        }
        return throwError(error);
      })
    );
  }

  authToken(loginService: LoginService) {
    const user = loginService.currentUser;
    const auth = {
      user_id: user.id,
      token: user.token,
    };
    return auth;
  }

  injectHeaders(request: HttpRequest<any>, loginService: LoginService): HttpRequest<any> {
    if (loginService.isLogged()) {
      return request.clone({
        setHeaders: { Authorization: `Bearer ${loginService.getCurrentUser(true).token}` },
        setParams: this.authToken(loginService),
      });
    }
    return request;
  }
}
