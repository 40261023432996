import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: "trackingGoogleMapsUrl",
})
export class TrackingGoogleMapsUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(tracking: any, args?: any): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `//maps.google.com/maps?q=${tracking.coordinates.lat_dest},${tracking.coordinates.lng_dest}&hl=es;z=14&output=embed&hl=pt-BR`
    );
  }
}
